import axios, { AxiosResponse } from "axios";

import { AccessToken } from "./tokenManager";
import { Maybe, Nullable } from "./types";
import { trimImage } from "./utils";
import { isDev } from "./enviromentHelper";
import { PosterBackgroundRemoval } from "./generated/graphql";

const removebgApiKey = process.env.REACT_APP_REMOVEBG_API_KEY;
const internalBackgroundRemovalApi =
  process.env.REACT_APP_INTERNAL_BACKGROUND_REMOVAL_URL || "";

const Api = {
  async removeImageBackground(
    imageFile: File,
    backgroundRemovalType: PosterBackgroundRemoval
  ): Promise<string> {
    const data = new FormData();
    data.append("image_file", imageFile);
    let responseData;
    if (backgroundRemovalType === PosterBackgroundRemoval.RemoveBg) {
      data.append("size", isDev ? "preview" : "full");
      data.append("crop", "true");

      const response = await axios.post(
        "https://api.remove.bg/v1.0/removebg",
        data,
        {
          headers: {
            "X-Api-Key": removebgApiKey,
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );
      responseData = response.data;
    } else {
      const response = await axios.post(internalBackgroundRemovalApi, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });
      responseData = response.data;
    }

    const trimmedImage = await trimImage(URL.createObjectURL(responseData));
    return trimmedImage as string;
  },
  uploadImage: async (image: File): Promise<string | undefined> => {
    const fileName = `${new Date().getTime()}.jpg`;
    const url = `${process.env.REACT_APP_POSTER_GENERATION_URL}/poster/entry-image-url/${fileName}`;
    const presignedResponse = await axios.get(url);

    const uploadResponse = await axios.put(
      presignedResponse.data.presignedUrl,
      image,
      {
        headers: {
          "Content-Type": "image/jpeg",
        },
      }
    );
    if (uploadResponse.status !== 200) {
      throw new Error("Could not upload image");
    }
    const uploadLocation = presignedResponse.data.presignedUrl.split("?")[0];
    return uploadLocation;
  },
};

interface ServerData {
  token: AccessToken;
  posters: { [pathname: string]: string }[];
}

export const PosterApi = {
  getToken: async (): Promise<Maybe<AccessToken>> => {
    try {
      const response: AxiosResponse<ServerData> = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_POSTER_GENERATION_URL}/token`,
      });

      return response.data.token;
    } catch (e) {
      throw e;
    }
  },
  bootstrap: async () => {
    try {
      const response: AxiosResponse = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_POSTER_GENERATION_URL}/bootstrap`,
      });

      return response.data;
    } catch (e) {
      console.log(e);
    }
  },
  createPosterGenerationJob: async (params) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_POSTER_GENERATION_URL}/poster`,
        data: params,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  },
  createCheckoutSession: async (params: {
    posterBaseUrl: string;
    posterId: string;
    posterPrice: number;
    posterPreviewUrl: Nullable<string>;
    customerEmail: string;
  }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_POSTER_GENERATION_URL}/create-checkout-session`,
        data: params,
      });
      return response;
    } catch (e) {
      console.log(e);
    }
  },
};

export default Api;
